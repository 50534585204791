.article-video.homeslider {
	border-radius: 25px;

	width: 100%;
	height: 100%;
	object-fit: cover;
	/*	aspect-ratio: 4/5;*/
	aspect-ratio: 2.35/1;
}
.tab-bullet-marker {
	background: linear-gradient(45deg, #f10388, #935dea);
}
