.img-slider-img {
	border-radius: 25px;

	width: 100%;
	height: 100%;
	object-fit: cover;
	/*	aspect-ratio: 4/5;*/
	aspect-ratio: 2/1;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
}
