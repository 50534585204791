.preloader-shimmer {
	animation-duration: 2.2s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: shimmer;
	animation-timing-function: linear;
	background: #ddd;
	background: linear-gradient(
		to right,
		#f6f6f609 8%,
		#f0f0f015 18%,
		#f6f6f609 33%
	);
	background-size: 200% 100%;
}

@-webkit-keyframes shimmer {
	0% {
		background-position: -100% 0;
	}
	100% {
		background-position: 100% 0;
	}
}
@keyframes shimmer {
	0% {
		background-position: 200% 0;
	}
	100% {
		background-position: -200% 0;
	}
}
