.landing-video {
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	list-style: none;
	-webkit-user-drag: none;
	margin: 0;
	padding: 0;
	border: 0;
	position: absolute;
	z-index: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
}

/*DESKTOP landscape*/
.landing-video {
	height: auto;
	width: 200%;
	/*	transform: translate(-25%, -25%);*/
}

/*16 / 9 reflects the aspect ratio of the DESKTOP a single video inside the side-by-side*/
/*DESKTOP portrait*/
@media (max-aspect-ratio: 16/9) {
	.landing-video {
		height: 200%;
		width: auto;
		/*	transform: translate(-25%, -25%);*/
	}
}

/*MOBILE landscape*/
.landing-video.mobile {
	height: 100%;
	width: auto;
	/*	transform: translate(-12.5%, -50%);*/
}

/*9 / 16 reflects the aspect ratio of the MOBILE a single video inside the side-by-side */
/*MOBILE portrait*/
@media (min-aspect-ratio: 9/16) {
	.landing-video.mobile {
		height: calc(101vw * 16 / 9);
		width: auto;
		/*		transform: translate(-12.5%, -50%);*/
	}
}

.article-video-cnt {
	position: relative;
}
.article-video {
	width: 100%;
	height: auto;
	transform: translateZ(0);
}

.fadeout {
	opacity: 0;
	transform: translateZ(0) scale(0.75);
}

.fadein {
	opacity: 1;
	transform: translateZ(0) scale(1);
	transition: all 0.3s cubic-bezier(0.84, 0.61, 0.4, 1);
}

.article-video-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	background-image: url("../img/home-vid-overlay-influencer.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 60% 50%;
}

.article-video.round {
	border-radius: 25px;
}

.article-el.padding {
	padding-left: 40px;
	padding-right: 40px;
}
.article-video.ar45 {
	width: 100%;
	height: 100%;
	object-fit: cover;

	aspect-ratio: 4/5;
}
.grad-txt {
	background: linear-gradient(45deg, #f10388, #935dea);
	background-size: 100%;
	padding-bottom: 10px;
	margin-bottom: -10px;
	/*	background: white;*/
	/*	text-align: center;*/
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	/*	animation: animated_text 10s ease-in-out infinite;
	-moz-animation: animated_text 10s ease-in-out infinite;
	-webkit-animation: animated_text 10s ease-in-out infinite;*/
}

.section-background.centered {
	z-index: -1;
	background: linear-gradient(
		125deg,
		#935deaff 12.9254386713%,
		#f1038866 37.9254386713%
	) !important;
	-webkit-mask: radial-gradient(circle at 50% 50%, black 0%, transparent 40%);
}

.section-background.top {
	z-index: -1;
	background: linear-gradient(
		125deg,
		#935deaff 12.9254386713%,
		#f1038866 37.9254386713%
	) !important;
	-webkit-mask: radial-gradient(circle at 50% 60%, black 0%, transparent 40%);
}

.section-background.top-left {
	widht: 100vw;
	height: 100vh;
	z-index: -1;
	background: linear-gradient(
		125deg,
		#935deaff 12.9254386713%,
		#f1038866 37.9254386713%
	) !important;
	-webkit-mask: radial-gradient(circle at 25% 50%, black 0%, transparent 25%);
	transform: translateX(15%) translateY(0%);
}

.section-background.top-center {
	widht: 100vw;
	height: 100vh;
	z-index: -1;
	background: linear-gradient(
		125deg,
		#935deaff 12.9254386713%,
		#f1038866 37.9254386713%
	) !important;
	-webkit-mask: radial-gradient(circle at 25% 50%, black 0%, transparent 30%);
	transform: translateX(30%) translateY(0%);
}
.section-background.right {
	z-index: -1;
	background: linear-gradient(
		125deg,
		#935deacc 12.9254386713%,
		#f1038822 37.9254386713%
	) !important;
	-webkit-mask: radial-gradient(circle at 50% 50%, black 0%, transparent 20%);
	transform: translateX(0%) translateY(0%);
}

@media screen and (max-width: 1280px) {
	.section-background.top {
		-webkit-mask: radial-gradient(
			circle at 50% 60%,
			black 0%,
			transparent 60%
		);
	}
	.section-background.top-left {
		-webkit-mask: radial-gradient(
			circle at 25% 50%,
			black 0%,
			transparent 30%
		);
		transform: translateX(0%) translateY(10%);
	}
}

@media screen and (max-width: 991px) {
	.section-background.top {
		-webkit-mask: radial-gradient(
			circle at 50% 60%,
			black 0%,
			transparent 70%
		);
	}
}

/*@media screen and (max-width: 767px) {
	.section-background.top {
		-webkit-mask: radial-gradient(
			circle at 50% 60%,
			black 0%,
			transparent 40%
		);
	}
}
@media screen and (max-width: 479px) {
	.section-background.top {
		-webkit-mask: radial-gradient(
			circle at 50% 60%,
			black 0%,
			transparent 40%
		);
	}
}*/

.glow-bg.left {
	position: absolute;
	pointer-events: none;
	width: 32%;
	height: 49%;
	max-width: 608px;
	max-height: 551px;
	z-index: -1;
	-webkit-transform: translate(-100px, -250px);
	-ms-transform: translate(-100px, -250px);
	transform: translate(-100px, -250px);
	background: linear-gradient(
			276.34deg,
			#935dea 10.26%,

			#f10388 85.74%
		),
		#935dea;
	opacity: 0.5;
	-webkit-filter: blur(177.206px);
	filter: blur(177.206px);
}

.glow-bg.modular {
	position: absolute;
	pointer-events: none;
	width: 32%;
	height: 49%;
	max-width: 608px;
	max-height: 551px;
	z-index: -1;

	background: linear-gradient(
			276.34deg,
			#935dea 10.26%,

			#f10388 85.74%
		),
		#935dea;
	opacity: 0.25;
	-webkit-filter: blur(177.206px);
	filter: blur(177.206px);
}

.glow-bg.realtime {
	position: absolute;
	pointer-events: none;
	width: 100%;
	height: 100%;

	z-index: -1;

	background: linear-gradient(
			276.34deg,
			#935dea 10.26%,

			#f10388 85.74%
		),
		#935dea;

	transform: translateY(-200px);
	opacity: 0.3;
	-webkit-filter: blur(177.206px);
	filter: blur(177.206px);
}

.glow-bg.right {
	position: absolute;
	pointer-events: none;
	width: 25%;
	height: 40%;
	max-width: 350px;
	max-height: 551px;
	z-index: -1;

	background: linear-gradient(
			276.34deg,
			#935dea 10.26%,

			#f10388 85.74%
		),
		#935dea;
	opacity: 0.75;
	-webkit-filter: blur(177.206px);
	filter: blur(177.206px);
}
